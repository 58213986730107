import React, { useState } from "react";

import classes from "./Cover.module.css";

const Cover = ({ image, text = "", height = "80vh", elementText = false }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={classes.container} style={{ height: height }}>
      <img
        src={image}
        className={loaded ? classes.loaded : ""}
        onLoad={() => setLoaded(true)}
        alt=""
      />
      {text.length > 0 && <h1 className={classes.title}>{text}</h1>}
      {elementText !== false && elementText}
    </div>
  );
};

export default Cover;
