import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./LazyImage.module.css";

const LazyImage = ({
  src,
  alt,
  navigateMe = false,
  nav = "/",
  className = "",
  onClick = () => {},
}) => {
  const navigate = useNavigate();
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const logoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Threshold for Visibility (0 - 1)
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsLogoLoaded(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const currentLogoRef = logoRef.current;

    if (currentLogoRef) observer.observe(currentLogoRef);

    return () => {
      if (currentLogoRef) {
        observer.unobserve(currentLogoRef);
      }
    };
  }, []);

  const clickHandler = () => (navigateMe ? navigate(nav) : onClick());

  return (
    <img
      ref={logoRef}
      className={`${classes.logo} ${
        isLogoLoaded ? classes.loaded : ""
      } ${className}`}
      src={isLogoLoaded ? src : ""}
      alt={alt}
      onClick={clickHandler}
    />
  );
};

export default LazyImage;
