import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import classes from "./Header.module.css";
import logo from "../../logo.svg";
import { TfiMenu } from "react-icons/tfi";
import { FaTimes } from "react-icons/fa";

const Header = () => {
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuOnClosing, setMenuOnClosing] = useState(false);

  const menuClickHandler = () => {
    if (!menuOnClosing) setMenuIsOpen(!menuIsOpen);
  };

  const closeHandler = () => {
    setMenuOnClosing(true);
    setTimeout(() => {
      setMenuOnClosing(false);
      setMenuIsOpen(false);
    }, 900);
  };

  useEffect(() => {
    if (menuIsOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [menuIsOpen]);

  return (
    <>
      {menuIsOpen && (
        <nav
          className={
            menuOnClosing ? `${classes.nav} ${classes.closing}` : classes.nav
          }
        >
          <div className={classes.title}>Menu</div>
          <ul>
            <NavLink
              to="/"
              className={(navData) =>
                navData.isActive
                  ? `${classes.navActive} ${classes.navLi}`
                  : classes.navLi
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/photos"
              className={(navData) =>
                navData.isActive
                  ? `${classes.navActive} ${classes.navLi}`
                  : classes.navLi
              }
            >
              Photos
            </NavLink>
            <NavLink
              to="/films"
              className={(navData) =>
                navData.isActive
                  ? `${classes.navActive} ${classes.navLi}`
                  : classes.navLi
              }
            >
              Films
            </NavLink>
            <NavLink
              to="/aboutme"
              className={(navData) =>
                navData.isActive
                  ? `${classes.navActive} ${classes.navLi}`
                  : classes.navLi
              }
            >
              About me
            </NavLink>
            <NavLink
              to="/contact"
              className={(navData) =>
                navData.isActive
                  ? `${classes.navActive} ${classes.navLi}`
                  : classes.navLi
              }
            >
              Contact
            </NavLink>
          </ul>
          <FaTimes className={classes.closeIcon} onClick={closeHandler} />
        </nav>
      )}

      <div className={classes.header}>
        <img
          onClick={() => navigate("/")}
          src={logo}
          className={classes.logo}
          alt="logo"
        />
        <TfiMenu onClick={menuClickHandler} className={classes.menuIcon} />
        <nav>
          <ul>
            <NavLink
              to="/"
              className={(navData) =>
                navData.isActive
                  ? `${classes.active} ${classes.li}`
                  : classes.li
              }
            >
              HOME
            </NavLink>
            <NavLink
              to="/photos"
              className={(navData) =>
                navData.isActive
                  ? `${classes.active} ${classes.li}`
                  : classes.li
              }
            >
              PHOTOS
            </NavLink>
            <NavLink
              to="/films"
              className={(navData) =>
                navData.isActive
                  ? `${classes.active} ${classes.li}`
                  : classes.li
              }
            >
              FILMS
            </NavLink>
            <NavLink
              to="/aboutme"
              className={(navData) =>
                navData.isActive
                  ? `${classes.active} ${classes.li}`
                  : classes.li
              }
            >
              ABOUT ME
            </NavLink>
            <NavLink
              to="/contact"
              className={(navData) =>
                navData.isActive
                  ? `${classes.active} ${classes.li}`
                  : classes.li
              }
            >
              CONTACT
            </NavLink>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
