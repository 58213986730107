import React from "react";

import PageWeapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Cover from "../components/UI/Cover/Cover";
import Footer from "../components/Footer/Footer";

import classes from "./Films.module.css";

//import { Player } from "video-react";
//import "../../node_modules/video-react/dist/video-react.css";

import cover from "../assets/contact/cover/cover.jpg";

const Films = () => {
  return (
    <PageWeapper>
      <Header />
      <Cover image={cover} text="Films" />
      <div className={classes.container}>
        <p>No films to show!</p>
      </div>
      <Footer />
    </PageWeapper>
  );
};

export default Films;
