import React from "react";
import { Link } from "react-router-dom";

import classes from "./Footer.module.css";

import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className={classes.footer}>
      <div className={classes.socialIcons}>
        <Link to={process.env.REACT_APP_CONTACT_FB} target="_blank">
          <FaFacebookSquare className={classes.fb} />
        </Link>

        <Link to={process.env.REACT_APP_CONTACT_IG} target="_blank">
          <FaInstagram className={classes.ig} />
        </Link>

        <Link to="/contact">
          <IoMdMail className={classes.mail} />
        </Link>
      </div>
      <p>
        Copyright ©{year} | Development and design by{" "}
        <Link
          to={"https://nedimdzanic.com"}
          target="_blank"
          className={classes.website}
        >
          www.nedimdzanic.com
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
