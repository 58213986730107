import React from "react";

import classes from "./Modal.module.css";

const Modal = ({ loading = false, error = false, title = "" }) => {
  const cssClasses = [
    classes.box,
    error && classes.error,
    loading && classes.loading,
  ];

  return (
    <div className={classes.container}>
      <p className={cssClasses.join(" ")}>{title}</p>
    </div>
  );
};

export default Modal;
