import React from "react";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Slideshow from "../components/Slideshow/Slideshow";
import Header from "../components/Header/Header";
import Button from "../components/UI/Button/Button";
import Footer from "../components/Footer/Footer";

import poster1 from "../assets/posters/1.jpg";
import poster2 from "../assets/posters/2.jpg";
import poster3 from "../assets/posters/3.jpg";

import classes from "./Home.module.css";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageWrapper>
        <>
          <Slideshow />
          <Header />

          <div className={classes.photoSection}>
            <div className={classes.textContainer}>
              <div className={classes.text}>
                <p>
                  Wedding photography and videography based in Bosnia and
                  Herzegovina
                </p>
                <p>Memories, details and emotions through the lens</p>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  title="Go to see"
                  onClick={() => {
                    navigate("/photos");
                  }}
                />
              </div>
            </div>

            <div className={classes.postersContainer}>
              <img src={poster1} alt="" />
              <img src={poster2} className={classes.middle} alt="" />
              <img src={poster3} alt="" />
            </div>
          </div>

          <div className={classes.test54}>
            <div className={classes.videoSection}>
              <iframe
                src="https://www.youtube.com/embed/QgArZm-QWHc?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=0&playsinline=1&rel=0&enablejsapi=1&playlist=QgArZm-QWHc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <Button
                className={classes.buttonFilms}
                title="Films"
                onClick={() => {
                  navigate("/films");
                }}
              />
            </div>
          </div>

          <Footer />
        </>
      </PageWrapper>
    </>
  );
};

export default Home;
