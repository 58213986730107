import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Photos from "./pages/Photos";
import Films from "./pages/Films";
import AboutMe from "./pages/AboutMe";
import Contact from "./pages/Contact";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route path="photos" element={<Photos />} />
        <Route path="films" element={<Films />} />
        <Route path="aboutme" element={<AboutMe />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
