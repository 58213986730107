import React from "react";

import { Slide } from "react-slideshow-image";
import { TypeAnimation } from "react-type-animation";
import Cover from "../UI/Cover/Cover";

import "react-slideshow-image/dist/styles.css";
import classes from "./Slideshow.module.css";

import img1 from "../../assets/slideshow/1.jpg";
import img2 from "../../assets/slideshow/2.jpg";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
};

const Slideshow = ({ children }) => {
  const slideImages = [
    {
      url: img1,
      caption: "",
    },
    {
      url: img2,
      caption: "",
    },
  ];

  return (
    <>
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <Cover
              image={slideImage.url}
              height="100vh"
              elementText={
                <TypeAnimation
                  sequence={["Hello,", 2000, "Welcome to my site.", 10000]}
                  wrapper="span"
                  speed={20}
                  className={classes.typingText}
                  repeat={Infinity}
                />
              }
            />
          </div>
        ))}
      </Slide>
      {children}
    </>
  );
};

export default Slideshow;
