import React from "react";
import { useNavigate } from "react-router-dom";

import PageWeapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Button from "../components/UI/Button/Button";
import Footer from "../components/Footer/Footer";
import classes from "./Page404.module.css";

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <PageWeapper backgroundColor="rgba(75,75,75, 0.6)">
      <Header />
      <div className={classes.container}>
        <h1>404</h1>
        <h2>Page not found</h2>
        <Button
          title="Back to home"
          className={classes.button}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      <Footer />
    </PageWeapper>
  );
};

export default Page404;
