import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import classes from "./Contact.module.css";

import PageWeapper from "../components/UI/PageWrapper/PageWrapper";
import Modal from "../components/UI/Modal/Modal";
import Header from "../components/Header/Header";
import Cover from "../components/UI/Cover/Cover";
import Footer from "../components/Footer/Footer";
import Button from "../components/UI/Button/Button";

import cover from "../assets/contact/cover/cover.jpg";

const Contact = () => {
  const referals = [
    "Facebook Ads",
    "Google search",
    "From a friend",
    "Instagram",
    "Other",
  ];
  const defaultReferal = "Select an option";

  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredDate, setEnteredDate] = useState("");
  const [enteredCity, setEnteredCity] = useState("");
  const [enteredLocation, setEnteredLocation] = useState("");
  const [enteredGuests, setEnteredGuests] = useState("");
  const [enteredServices, setEnteredServices] = useState("");
  const [enteredReferal, setEnteredReferal] = useState(defaultReferal);
  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [servicesError, setServicesError] = useState(false);
  const [referalError, setReferalError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const dateRef = useRef(null);
  const cityRef = useRef(null);
  const locationRef = useRef(null);
  const servicesRef = useRef(null);
  const referalRef = useRef(null);
  const phoneRef = useRef(null);

  const [sending, setSending] = useState(false); //1 sending | 2 success | 3 error

  const nameChangeHandler = (e) => {
    setEnteredName(e.target.value);
    e.target.value.trim().length < 1 ? setNameError(true) : setNameError(false);
  };

  const emailChangeHandler = (e) => {
    setEnteredEmail(e.target.value);
    e.target.value.trim().length < 1
      ? setEmailError(true)
      : setEmailError(false);
  };

  const dateChangeHandler = (e) => {
    setEnteredDate(e.target.value);
    e.target.value.trim().length < 1 ? setDateError(true) : setDateError(false);
  };

  const cityChangeHandler = (e) => {
    setEnteredCity(e.target.value);
    e.target.value.trim().length < 1 ? setCityError(true) : setCityError(false);
  };

  const locationChangeHandler = (e) => {
    setEnteredLocation(e.target.value);
    e.target.value.trim().length < 1
      ? setLocationError(true)
      : setLocationError(false);
  };

  const guestsChangeHandler = (e) => setEnteredGuests(e.target.value);

  const servicesChangeHandler = (e) => {
    setEnteredServices(e.target.value);
    setServicesError(false);
  };

  const referalChangeHandler = (e) => {
    setEnteredReferal(e.target.value);

    referals.includes(e.target.value)
      ? setReferalError(false)
      : setReferalError(true);
  };

  const phoneChangeHandler = (e) => {
    setEnteredPhone(e.target.value);
    e.target.value.trim().length < 1
      ? setPhoneError(true)
      : setPhoneError(false);
  };

  const messageChangeHandler = (e) => setEnteredMessage(e.target.value);

  const scrollToLabel = (refName) =>
    refName.current?.scrollIntoView({ behavior: "smooth" });

  const clearModal = () => {
    setSending(false);

    setEnteredName("");
    setEnteredEmail("");
    setEnteredDate("");
    setEnteredCity("");
    setEnteredLocation("");
    setEnteredGuests("");
    setEnteredServices("");
    setEnteredReferal(defaultReferal);
    setEnteredPhone("");
    setEnteredMessage("");
    setNameError(false);
    setEmailError(false);
    setDateError(false);
    setCityError(false);
    setLocationError(false);
    setServicesError(false);
    setReferalError(false);
    setPhoneError(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (enteredName.trim().length < 1) {
      setNameError(true);
      scrollToLabel(nameRef);
    } else if (enteredEmail.trim().length < 1) {
      setEmailError(true);
      scrollToLabel(emailRef);
    } else if (enteredDate.trim().length < 1) {
      setDateError(true);
      scrollToLabel(dateRef);
    } else if (enteredCity.trim().length < 1) {
      setCityError(true);
      scrollToLabel(cityRef);
    } else if (enteredLocation.trim().length < 1) {
      setLocationError(true);
      scrollToLabel(locationRef);
    } else if (enteredServices.trim().length < 1) {
      setServicesError(true);
      scrollToLabel(servicesRef);
    } else if (enteredReferal === defaultReferal) {
      setReferalError(true);
      scrollToLabel(referalRef);
    } else if (enteredPhone.trim().length < 1) {
      setPhoneError(true);
      scrollToLabel(phoneRef);
    } else {
      const params = {
        name: enteredName,
        email: enteredEmail,
        date: enteredDate,
        city: enteredCity,
        location: enteredLocation,
        guests: enteredGuests,
        referal: enteredReferal,
        service: enteredServices,
        phone: enteredPhone,
        message: enteredMessage,
      };

      setSending(1);

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          params,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then(
          (response) => {
            setSending(2);
            setTimeout(clearModal, 3000);
          },
          (err) => {
            setSending(3);
            setTimeout(clearModal, 3000);
            console.log("FAILED...", err);
          }
        );
    }
  };

  return (
    <PageWeapper>
      {sending === 1 && <Modal loading={true} title="Sending..." />}
      {sending === 2 && <Modal loading={false} title="Sent successfully!" />}
      {sending === 3 && <Modal error={true} title="Error!" />}
      <Header />
      <Cover image={cover} text="Contact" />
      <form className={classes.form} onSubmit={submitHandler}>
        <label className={classes.label} htmlFor="name" ref={nameRef}>
          Your name *
        </label>
        <input
          type="text"
          id="name"
          onChange={nameChangeHandler}
          className={nameError ? classes.inputError : ""}
          value={enteredName}
        />

        <label className={classes.label} htmlFor="mail" ref={emailRef}>
          Email address *
        </label>
        <input
          type="email"
          id="mail"
          autoComplete="true"
          onChange={emailChangeHandler}
          className={emailError ? classes.inputError : ""}
          value={enteredEmail}
        />

        <label className={classes.label} htmlFor="date" ref={dateRef}>
          Date of weddings *
        </label>
        <input
          type="date"
          id="date"
          onChange={dateChangeHandler}
          className={dateError ? classes.inputError : ""}
          value={enteredDate}
        />

        <label className={classes.label} htmlFor="userFrom" ref={cityRef}>
          Where are you from *
        </label>
        <input
          type="text"
          id="userFrom"
          onChange={cityChangeHandler}
          className={cityError ? classes.inputError : ""}
          value={enteredCity}
        />

        <label className={classes.label} htmlFor="location" ref={locationRef}>
          Location of the wedding *
        </label>
        <input
          type="text"
          id="location"
          onChange={locationChangeHandler}
          className={locationError ? classes.inputError : ""}
          value={enteredLocation}
        />

        <label className={classes.label} htmlFor="guests">
          Number of guests
        </label>
        <input
          type="number"
          id="guests"
          onChange={guestsChangeHandler}
          value={enteredGuests}
        />

        <label className={classes.label} htmlFor="services" ref={servicesRef}>
          What type of services are you looking for? *
        </label>
        <div
          className={
            servicesError
              ? `${classes.servicesContainer} ${classes.inputError}`
              : classes.servicesContainer
          }
        >
          <input
            type="radio"
            id="Photography"
            name="services"
            value="Photography"
            onChange={servicesChangeHandler}
          />
          <label htmlFor="Photography">Photography</label>
          <br />
          <input
            type="radio"
            id="Videography"
            name="services"
            value="Videography"
            onChange={servicesChangeHandler}
          />
          <label htmlFor="Videography">Videography</label>
          <br />
          <input
            type="radio"
            id="Both"
            name="services"
            value="Both"
            onChange={servicesChangeHandler}
          />
          <label htmlFor="Both">Both</label>
        </div>

        <label className={classes.label} htmlFor="referal" ref={referalRef}>
          How did you hear about us? *
        </label>
        <br />
        <select
          name="referal"
          id="referal"
          onChange={referalChangeHandler}
          className={referalError ? classes.inputError : ""}
          value={enteredReferal}
        >
          <option value="select">{defaultReferal}</option>
          {referals.map((referal) => (
            <option key={referal} value={referal}>
              {referal}
            </option>
          ))}
        </select>

        <label className={classes.label} htmlFor="phone" ref={phoneRef}>
          Your phone number *
        </label>
        <input
          type="tel"
          id="phone"
          autoComplete="true"
          onChange={phoneChangeHandler}
          className={phoneError ? classes.inputError : ""}
          value={enteredPhone}
        />

        <label className={classes.label} htmlFor="message">
          Message
        </label>
        <br />
        <textarea
          name="message"
          id="message"
          onChange={messageChangeHandler}
          value={enteredMessage}
        />

        <div className={classes.buttonFlex}>
          <Button type="submit" title="Submit" />
        </div>
      </form>
      <Footer />
    </PageWeapper>
  );
};

export default Contact;
