import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";

import classes from "./AboutMe.module.css";

import PageWeapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Cover from "../components/UI/Cover/Cover";
import Footer from "../components/Footer/Footer";
import LazyImage from "../components/UI/LazyImage/LazyImage";

import profileImg from "../assets/aboutme/profile/emirramic.jpg";
import cover from "../assets/aboutme/cover/cover.jpg";

const thumbnails = require.context("../assets/aboutme/thumbnails/", true);
const thumbnailList = thumbnails
  .keys()
  .sort((a, b) => {
    const indexA = parseInt(a.split("/").pop().split(".")[0]);
    const indexB = parseInt(b.split("/").pop().split(".")[0]);
    return indexA - indexB;
  })
  .map((image) => thumbnails(image));

const images = require.context("../assets/aboutme/originals/", true);
const imagelList = images
  .keys()
  .sort((a, b) => {
    const indexA = parseInt(a.split("/").pop().split(".")[0]);
    const indexB = parseInt(b.split("/").pop().split(".")[0]);
    return indexA - indexB;
  })
  .map((image) => images(image));

const AboutMe = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (isViewerOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isViewerOpen]);

  return (
    <PageWeapper>
      <Header />
      <Cover image={cover} text="About me" />
      <div className={classes.aboutmeContainer}>
        <div className={classes.profileContainer}>
          <LazyImage
            alt="Emir Ramić"
            src={profileImg}
            className={classes.profile}
          />
        </div>

        <p>
          Welcome to my site.
          <br />
          My name is Emir Ramić.
          <br />I live in Sanski Most, Bosnia and Herzegovina.
          <br />I have been involved in photography and videography for 5 years
          and I try to preserve <br />
          your important events from oblivion through my lens.
          <br />I am here to "capture" all those moments and save them for
          eternity,
          <br />
          and thanks to the camera, they are priceless.
          <br />
          Through my experience, I had the opportunity to photograph
          <br />
          many famous personalities such as Novak Đoković, Gabriel Batistuta,
          <br />
          Jala Brat, Aca Lukas, Dženan Lončarević, Filip Kostić, Željko
          <br />
          Samardžić and others.
          <br />
          Get ready for taking photos and recording,
          <br />
          because photos are there to preserve a moment that has passed forever
          <br />
          and is impossible to repeat.
          <br />
          The camera and I are available all over the word.
          <br />I am here for all your questions.
        </p>
      </div>
      <div className={classes.imagesContainer}>
        {thumbnailList.map((thumbnail, index) => (
          <LazyImage
            alt={`image-${index}`}
            key={index}
            src={thumbnail}
            onClick={() => openImageViewer(index)}
          />
        ))}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={imagelList}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: "1000",
          }}
        />
      )}
      <Footer />
    </PageWeapper>
  );
};

export default AboutMe;
