import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";

import PageWeapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Cover from "../components/UI/Cover/Cover";
import LazyImage from "../components/UI/LazyImage/LazyImage";
import Footer from "../components/Footer/Footer";

import classes from "./Photos.module.css";

import cover from "../assets/portofolio/cover/cover.jpg";

const thumbnails = require.context("../assets/portofolio/thumbnails/", true);
const thumbnailList = thumbnails
  .keys()
  .sort((a, b) => {
    const indexA = parseInt(a.split("/").pop().split(".")[0]);
    const indexB = parseInt(b.split("/").pop().split(".")[0]);
    return indexA - indexB;
  })
  .map((image) => thumbnails(image));

const images = require.context("../assets/portofolio/originals/", true);
const imagelList = images
  .keys()
  .sort((a, b) => {
    const indexA = parseInt(a.split("/").pop().split(".")[0]);
    const indexB = parseInt(b.split("/").pop().split(".")[0]);
    return indexA - indexB;
  })
  .map((image) => images(image));

const Photos = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (isViewerOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isViewerOpen]);

  return (
    <PageWeapper>
      <Header />
      <Cover image={cover} text="Photos" />
      <div className={classes.container}>
        {thumbnailList.map((thumbnail, index) => (
          <LazyImage
            alt={`image-${index}`}
            key={index}
            src={thumbnail}
            onClick={() => openImageViewer(index)}
          />
        ))}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={imagelList}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: "1000",
          }}
        />
      )}
      <Footer />
    </PageWeapper>
  );
};

export default Photos;
