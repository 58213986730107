import React from "react";

import classes from "./PageWrapper.module.css";

const PageWrapper = ({ backgroundColor = "", children }) => {
  return (
    <div className={classes.container} style={{ backgroundColor }}>
      {children}
    </div>
  );
};

export default PageWrapper;
